exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cooking-index-tsx": () => import("./../../../src/pages/cooking/index.tsx" /* webpackChunkName: "component---src-pages-cooking-index-tsx" */),
  "component---src-pages-cooking-tags-tsx": () => import("./../../../src/pages/cooking/tags.tsx" /* webpackChunkName: "component---src-pages-cooking-tags-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mia-tsx": () => import("./../../../src/pages/mia.tsx" /* webpackChunkName: "component---src-pages-mia-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

